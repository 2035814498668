import { useAuth } from '@redwoodjs/auth'
import type { inferRouterInputs, inferRouterOutputs } from '@trpc/server'
import type { WebRouter } from 'api'
import * as React from 'react'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { createTRPCReact } from '@trpc/react-query'
import { httpBatchLink } from '@trpc/client'

import _isEqual from 'lodash/isEqual'

// fix The inferred type of trpc cannot be named without a reference to
export const trpc: ReturnType<typeof createTRPCReact<WebRouter>> =
  createTRPCReact<WebRouter>()

export const TRPC = ({ children }: { children: React.ReactNode }) => {
  const { getToken, isAuthenticated, loading } = useAuth()
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retryDelay: 500, // half of the default: `1000`
            useErrorBoundary: (error: any, query) => {
              // catch only for `user.entry` route.
              return _isEqual(query.queryKey[0], ['user', 'entry'])
            },
          },
        },
      })
  )

  const [trpcClient] = React.useState(() => {
    return trpc.createClient({
      links: [
        httpBatchLink({
          url: process.env.SNAPLET_API_HOSTNAME + '/web',
          async headers() {
            if (isAuthenticated) {
              return {}
            }
            const token = await getToken()
            return {
              'auth-provider': 'auth0',
              authorization: `Bearer ${token}`,
            }
          },
        }),
      ],
    })
  })

  if (loading && !isAuthenticated) {
    return null
  }

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </trpc.Provider>
  )
}

export const useQueries = <TQueries extends Record<string, any>>(
  queries: TQueries
) => {
  const error = Object.values(queries).some((query: any) => {
    return query.error
  })

  const isLoading = Object.values(queries).some((query: any) => {
    return query.isLoading
  })

  return {
    ...queries,
    error,
    isLoading,
  }
}

export type RouterInput = inferRouterInputs<WebRouter>
export type RouterOutput = inferRouterOutputs<WebRouter>
