// @ts-nocheck
const variantMap = {
  primary: `
    text-white bg-iris-800
    hover:bg-iris-400
    active:text-iris-200 disabled:bg-iris-400 disabled:text-gray-200`,
  secondary: `
    text-black bg-white
    hover:bg-gray-200
    active:text-gray-200
  `,
  inverted: `
    text-teal-800 bg-black
    hover:text-white hover:bg-teal-800
    active:text-teal-400
  `,
  destructive: `
    text-white bg-red-800 shadow-none
    hover:text-black hover:bg-red-400
    active:text-white
  `,
}

type Variant = keyof typeof variantMap

type RenderComponent = React.ComponentType | 'a' | 'button'

type Props<E extends RenderComponent> = {
  as?: E
  disabled?: boolean
  variant?: Variant
  className?: string
  children: React.ReactNode
  accessory?: string
} & React.ComponentProps<E>

export const Button = <T extends RenderComponent = 'button'>({
  as,
  variant = 'primary',
  disabled,
  className = '',
  children,
  ...rest
}: Props<T>): ReturnType<React.FC<Props<T>>> => {
  const Component = as || 'button'

  return (
    <Component
      type="button"
      disabled={disabled}
      className={
        'px-3 font-serif text-base text-center transition duration-100 ease-in-out border rounded shadow whitespace-nowrap h-9 leading-9' +
        ' focus:outline-none active:shadow-none' +
        ' disabled:shadow-none disabled:cursor-default disabled:bg-white disabled:text-gray-400 disabled:border-secondary' +
        ' ' +
        variantMap[variant] +
        ' ' +
        className
      }
      {...rest}
    >
      {children}
    </Component>
  )
}
