import { ReactNode } from 'react'

export const Card = ({
  className,
  variant = 'default',
  ...rest
}: {
  className?: string
  children: ReactNode
  variant?: 'default' | 'popup'
}) => {
  const variantStyles = {
    default: 'rounded border-gray-200 border',
    popup: 'border rounded-xl shadow-soft-2',
  }[variant]
  return (
    <div className={`bg-white ${variantStyles} ${className ?? ''}`} {...rest} />
  )
}

const Header = ({
  title,
  children,
  className = '',
  ...rest
}: {
  title: ReactNode
  to?: string
  className?: string
  children?: ReactNode
}) => {
  return (
    <div
      className={
        'flex items-center px-5 text-white bg-gray-800 border-b rounded rounded-b-none h-13 font-bold' +
        ' ' +
        className
      }
      {...rest}
    >
      {title}
      {children && <div className="ml-auto">{children}</div>}
    </div>
  )
}

const Body = ({
  children,
  className = '',
  ...rest
}: {
  className?: string
  children: ReactNode
}) => {
  return (
    <div className={'p-5' + ' ' + className} {...rest}>
      {children}
    </div>
  )
}

const Footer = ({
  children,
  className = '',
  ...rest
}: {
  className?: string
  children: ReactNode
}) => {
  return (
    <div className={'text-center p-5' + ' ' + className} {...rest}>
      {children}
    </div>
  )
}

const Actions = ({
  children,
  className = '',
  ...rest
}: {
  className?: string
  children: ReactNode
}) => {
  return (
    <div
      className={'flex mt-3 justify-end space-x-4' + ' ' + className}
      {...rest}
    >
      {children}
    </div>
  )
}

Card.Header = Header
Card.Body = Body
Card.Footer = Footer
Card.Actions = Actions
