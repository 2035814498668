import { NavLink } from '@redwoodjs/router'

export const TabItem = ({ to, label }: { to: string; label: string }) => {
  return (
    // @ts-expect-error: onClick is expected in NavLink!?
    <NavLink
      to={to}
      className="flex items-center py-2 hover:text-iris-800"
      activeClassName="text-iris-800"
    >
      {label}
    </NavLink>
  )
}
