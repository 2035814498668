import { useDataEditorMatch } from 'src/lib/hooks'
import { cn } from 'src/lib/cn'

export const WorkspaceLayout = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const isDataEditorPage = useDataEditorMatch()

  return (
    <div
      className={cn({ 'mx-6 pb-6': !isDataEditorPage }, 'flex-1 flex flex-col')}
    >
      {children}
    </div>
  )
}
