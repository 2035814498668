import { useEffect } from 'react'
import { AuthProvider } from '@redwoodjs/auth'
import * as Sentry from '@sentry/browser'
import { setAutoFreeze } from 'immer'
import posthog from 'posthog-js'
import { Toaster } from 'react-hot-toast'
import { RedwoodReactQueryProvider } from 'redwoodjs-react-query-provider'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import Routes from 'src/Routes'
import { GenericErrorBoundary } from 'src/components/GenericErrorBoundary'

import './index.css'
import { auth0 } from './lib/auth0'
import { TRPC } from './lib/trpc'
import kebabCase from 'lodash/kebabCase'

export const IS_PRODUCTION =
  process.env.NODE_ENV === 'production' && Boolean(process.env.STAGE) === false

// context(justinvdm, 15 Feb 2022): Done to preserve old behaviour pre-v8
// (did this to be safe - no checks were done that this is in fact needed)
// https://github.com/immerjs/immer/releases/tag/v8.0.0
setAutoFreeze(process.env.NODE_ENV !== 'production')
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://109fc30c19764ba9aab508c85c3eff89@o503558.ingest.sentry.io/4504238358528000',
    environment: process.env.STAGE
      ? kebabCase(process.env.STAGE).slice(0, 63)
      : 'production',
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
  })
  Sentry.setTag('side', 'web')
}

// This is required to authenticate via GraphQL.
window.RWJS_API_GRAPHQL_URL = process.env.SNAPLET_API_HOSTNAME + '/graphql'

posthog.init(process.env.POSTHOG_API_KEY!, {
  api_host: 'https://app.posthog.com',
  loaded(posthog) {
    posthog.register({
      source: 'app',
    })
  },
})

export default () => {
  useEffect(() => {
    // once the user is redirected back from supabase, their query params will
    // ... contain a `state` param, which is the projectId (e.g. `?state=1`)
    // ... this breaks auth0, assume due to a clash with the `state` param used by auth0
    // ... so we redirect the user to a new url with the projectId as a new param
    if (window.location.pathname.includes('connect-to-supabase')) {
      const params = new URLSearchParams(window.location.search)
      const state = params.get('state')

      if (state) {
        const code = params.get('code')
        window.location.replace(
          `${window.location.origin}/connect-to-supabase?code=${code}&projectId=${state}`
        )
      }
    }
  }, [])

  return (
    <GenericErrorBoundary>
      <AuthProvider client={auth0} type="auth0">
        <TRPC>
          <RedwoodReactQueryProvider>
            <Routes />
            <Toaster />
            <ReactQueryDevtools />
          </RedwoodReactQueryProvider>
        </TRPC>
      </AuthProvider>
    </GenericErrorBoundary>
  )
}
