import { Link, routes, useLocation, useParams } from '@redwoodjs/router'
import { ReactNode } from 'react'
import { motion } from 'framer-motion'

import { cn } from 'src/lib/cn'
import { useDataEditorMatch } from 'src/lib/hooks'
import { trpc } from 'src/lib/trpc'

export const NavItem = ({
  to,
  children,
}: {
  to: string
  children: ReactNode
}) => {
  const { pathname } = useLocation()

  // account for url params
  const active = to === pathname || `${to}?`.startsWith(pathname)
  return (
    <Link
      className={cn(
        'px-3 py-2 rounded-lg relative group transition-colors',
        !active && 'hover:bg-gray-200 hover:bg-opacity-30'
      )}
      onClick={() => {}}
      {...{ to }}
    >
      {active ? (
        <motion.div
          layoutId="nav-item-background"
          className="absolute inset-0 bg-gray-200 bg-opacity-50 rounded-lg z-[0] "
        />
      ) : null}
      <div className="z-50 relative">{children}</div>
    </Link>
  )
}

const GaurdedNavItem = ({
  databaseId,
  organizationId,
}: {
  databaseId: string
  organizationId: string
}) => {
  const projectTabQuery = trpc.database.projectTab.useQuery({ databaseId })

  if (projectTabQuery.isLoading) {
    return (
      <div className="h-[16px] bg-gray-200 animate-pulse w-28 inline-block rounded-md" />
    )
  }

  return (
    <>
      {projectTabQuery.data?.isSnapshotsEnabled ? (
        <>
          <NavItem
            to={routes.project_overview({
              organizationId,
              databaseId,
              subpage: 'snapshots',
            })}
          >
            Snapshots
          </NavItem>
          <NavItem
            to={routes.project_data_editor({
              organizationId,
              databaseId,
              section: 'replace',
            })}
          >
            Data Editor
          </NavItem>
          <NavItem
            to={routes.project_overview({
              organizationId,
              databaseId,
              subpage: 'previewdbs',
            })}
          >
            Preview Databases
          </NavItem>
          <NavItem
            to={routes.project_overview({
              organizationId,
              databaseId,
              subpage: 'activity',
            })}
          >
            Activity
          </NavItem>
        </>
      ) : null}
      {projectTabQuery.data?.isSeedEnabled ? (
        <NavItem
          to={routes.project_overview({
            organizationId,
            databaseId,
            subpage: 'seed',
          })}
        >
          Data Generator
        </NavItem>
      ) : null}
      {!projectTabQuery.data?.isSnapshotsEnabled ? (
        <NavItem
          to={routes.onboarding({ organizationId, databaseId, step: 'start' })}
        >
          Snapshot (Onboarding)
        </NavItem>
      ) : null}
    </>
  )
}

export const ProjectTabs: React.FC = ({ children }) => {
  const { organizationId, databaseId } = useParams()

  const isDataEditorPage = useDataEditorMatch()

  return (
    <>
      <div
        className={cn(
          'flex px-6 py-2 space-x-2 bg-white -mt-7 border-b border-t border-gray-200 border-opacity-40 items-center',
          { 'mb-5': !isDataEditorPage }
        )}
      >
        <NavItem
          to={routes.project_overview({
            organizationId,
            databaseId,
            subpage: 'overview',
          })}
        >
          Overview
        </NavItem>
        <GaurdedNavItem {...{ databaseId, organizationId }} />
        <NavItem to={routes.project_settings({ organizationId, databaseId })}>
          Settings
        </NavItem>
      </div>
      {children}
    </>
  )
}
