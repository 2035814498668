// @ts-nocheck
import { Router, Route, Set, routes, navigate, useParams } from '@redwoodjs/router'
import React, { ReactNode, useEffect } from 'react'

import { Button } from 'src/components/Button'
import { Header } from 'src/layouts/Header'
import { HeaderWithBreadcrumbs } from 'src/layouts/HeaderWithBreadcrumbs'
import { WorkspaceLayout } from 'src/layouts/WorkspaceLayout'
import { ProjectListLoader } from 'src/routes/projects/loaders/List'
import { SettingsLoader } from 'src/routes/projects/loaders/Settings'
import { SnapshotsLoader } from 'src/routes/projects/loaders/Snapshots'
import { DataOperationsLoader } from 'src/routes/projects/loaders/DataOperations'
import { TeamListLoader } from 'src/routes/teams/loaders/List'

import { PosthogPageviewWrap, PosthogUserWrap } from './layouts/Posthog'
import { ProjectTabs } from './routes/projects/layouts/ProjectTabs'
import { OrganizationTabs } from './routes/teams/layouts/OrganizationTabs'
import { trpc } from './lib/trpc'
import { AnimatePresence, motion } from 'framer-motion'

const UserLogin = { name: 'Login', loader: () => import('./routes/user/pages/Login') }
const UserLogout = { name: 'Logout', loader: () => import('./routes/user/pages/Logout') }
const UserRedirect = { name: 'Redirect', loader: () => import('./routes/user/pages/Redirect') }
const UserAccessTokenClient = { name: 'UserAccessTokenClient', loader: () => import('./routes/user/pages/ClientAccessToken') }
const UserAccessTokenCreate = { name: 'UserAccessTokenCreate', loader: () => import('./routes/user/pages/ClientAccessToken') }
const UserAccessTokens = { name: 'UserAccessTokens', loader: () => import('./routes/user/pages/AccessTokens') }
const UserAccountDeletion = { name: 'UserAccountDeletion', loader: () => import('./routes/user/pages/AccountDeletion') }

const TeamList = { name: 'TeamList', loader: () => import('./routes/teams/pages/List') }
const TeamProjects = { name: 'TeamProjects', loader: () => import('./routes/teams/pages/Projects') }
const TeamActivity = { name: 'TeamActivity', loader: () => import('./routes/teams/pages/Activity') }
const TeamMetrics = { name: 'TeamMetrics', loader: () => import('./routes/teams/pages/Metrics') }
const TeamMembers = { name: 'TeamMembers', loader: () => import('./routes/teams/pages/Members') }
const TeamSettings = { name: 'TeamSettings', loader: () => import('./routes/teams/pages/Settings') }
const TeamJoin = { name: 'TeamJoin', loader: () => import('./routes/teams/pages/Join') }
const TeamBilling = { name: 'TeamBilling', loader: () => import('./routes/teams/pages/Billing') }

const ProjectSnapshots = { name: 'ProjectSnapshots', loader: () => import('./routes/projects/pages/Overview') }
const ProjectSnapshotDetail = { name: 'ProjectSnapshotDetail', loader: () => import('./routes/projects/pages/SnapshotDetail') }
const ProjectDataOperations = { name: 'ProjectDataOperations', loader: () => import('./routes/projects/pages/DataOperations') }
const ProjectSettings = { name: 'ProjectSettings', loader: () => import('./routes/projects/pages/Settings') }
const ProjectExecTask = { name: 'ProjectExecTask', loader: () => import('./routes/projects/pages/ExecTask') }

const AdminExecTaskLogs = { name: 'AdminExecTask', loader: () => import('./routes/projects/pages/ExecTask') }
const PaddlePayRedirect = { name: 'PaddlePayRedirect', loader: () => import('./routes/projects/pages/PaddlePayRedirect') }

const Onboarding = { name: 'Onboarding', loader: () => import('./routes/onboarding/pages/Onboarding') }

export default () => {
  return (
    <Router>
      <Set private unauthenticated="user_login">
        <Route path="/" name="entry" page={UserRedirect} />
      </Set>

      <Set wrap={[PosthogPageviewWrap, RemoveLoaderWrap]}>
        <Route path="/health" page={() => <>200 - OK</>} name="health" />
        <Route path="/login" page={UserLogin} name="user_login" />
        <Route path="/logout" page={UserLogout} name="user_logout" />
        {/* rename to connect/supabase */}
        <Route path="/connect-to-supabase" page={ConnectedToSupabase} name="connect_to_supabase" />
        <Route
          notfound
          // Components in parent Set `wrap` prop don't seem to mount
          // ... if notfound prop is enabled.
          page={() => (
            <RemoveLoaderWrap>
              <NotFound />
            </RemoveLoaderWrap>
          )}
        />

        <Set wrap={[Header, WorkspaceLayout]}>
          <Route path="/team/join/{token}" name="team_join" page={TeamJoin} />
        </Set>

        <Set private unauthenticated="user_login" wrap={[PosthogUserWrap]}>
          <Route path="/" name="entry" page={UserRedirect} />

          <Set wrap={[Header, WorkspaceLayout]}>
            <Route path="/o/{organizationId}/p/{databaseId}/onboarding/{step}" name="onboarding" page={Onboarding} />
            <Route path="/account-deletion" name="account_deletion" page={UserAccountDeletion} />
            <Route path="/access-tokens" name="user_access_tokens" page={UserAccessTokens} />
            <Route path="/access-token/create" name="user_access_token_create" page={UserAccessTokenCreate} />
            <Route path="/access-token/{type}" name="__accessToken" page={UserAccessTokenClient} />
          </Set>

          <Set wrap={[HeaderWithBreadcrumbs]}>
            <Set wrap={[WorkspaceLayout]}>
              <Route path="/o" name="teams" page={TeamList} whileLoadingPage={TeamListLoader} />
            </Set>

            <Set wrap={[OrganizationTabs, WorkspaceLayout]}>
              <Route path="/o/{organizationId}" name="team_projects" page={TeamProjects} whileLoadingPage={ProjectListLoader} />
              <Route path="/o/{organizationId}/activity" name="team_activity" page={TeamActivity} />
              <Route path="/o/{organizationId}/metrics" name="team_metrics" page={TeamMetrics} />
              <Route path="/o/{organizationId}/members" name="team_members" page={TeamMembers} />
              <Route path="/o/{organizationId}/billing" name="team_billing" page={TeamBilling} />
              <Route path="/o/{organizationId}/settings" name="team_settings" page={TeamSettings} />
            </Set>

            {/* Projects */}
            <Set wrap={[LastSeenProjectWrap]}>
              <Set wrap={[WorkspaceLayout]}>
                <Route path="/o/{organizationId}/p/{databaseId}/snapshots/{snapshotId}/{section}" name="project_snapshot" page={ProjectSnapshotDetail} />
              </Set>

              <Set wrap={[ProjectTabs, WorkspaceLayout]}>
                <Route path="/o/{organizationId}/p/{databaseId}/data-editor/{section}" name="project_data_editor" page={ProjectDataOperations} whileLoadingPage={() => <DataOperationsLoader />} />
                <Route path="/o/{organizationId}/p/{databaseId}/settings" name="project_settings" page={ProjectSettings} whileLoadingPage={() => <SettingsLoader />} />
                <Route path="/o/{organizationId}/p/{databaseId}/exec-task/{execTaskId}" name="project_exec_task" page={ProjectExecTask} />
                <Route path="/o/{organizationId}/p/{databaseId}/{subpage}" name="project_overview" page={ProjectSnapshots} whileLoadingPage={SnapshotsLoader} />
                <Route path="/o/{organizationId}/p/{databaseId}/{subpage}/{seedPath}" name="project_overview_seed_expanded" page={ProjectSnapshots} whileLoadingPage={SnapshotsLoader} />
              </Set>
            </Set>
          </Set>

          <Set wrap={[Header, WorkspaceLayout]}>
            <Route path="/admin/exec-task/{execTaskId}/logs" name="admin_snapshot_logs" page={AdminExecTaskLogs} />
          </Set>
          <Set wrap={[Header, WorkspaceLayout]}>
            <Route path="/paddlepay" name="paddle_pay" page={PaddlePayRedirect} />
          </Set>
        </Set>
      </Set>
    </Router>
  )
}

// store last interacted with project in localStorage.
// ... used /web/src/routes/user/pages/Redirect/index.tsx
const LastSeenProjectWrap = ({ children }: { children: ReactNode }) => {
  const { databaseId, organizationId } = useParams()

  React.useEffect(() => {
    // disable on localhost
    if (window.location.hostname !== 'localhost') {
      localStorage.setItem('lastSeenProjectUrl', routes.project_overview({ organizationId, databaseId, subpage: 'overview' }))
    }
  }, [databaseId, organizationId])

  return children
}

export const RemoveLoaderWrap = ({ children }: { children: JSX.Element }) => {
  React.useEffect(() => {
    const element = document.getElementById('heart-beat-loader')
    if (element) {
      // Add fade out animation
      element.className = 'loader-fade-out'

      // Remove loader from dom, with just
      // ... enough time to fade out
      setTimeout(() => {
        element.style.display = 'none'
      }, 150)
    }
  }, [])

  return children
}

const NotFound = () => {
  return (
    <div className="flex items-center h-full">
      <div className="p-5 m-auto space-y-6 bg-white border shadow-soft">
        <h1 className="font-serif font-bold">404 - Page not found</h1>
        <p>Hmpf. The page that you've accessed could not be found.</p>
        <Button onClick={() => navigate(routes.entry())}>Back home</Button>
      </div>
    </div>
  )
}

// when a user is redirected to /connect-to-supabase from supabase
// we need to save the auth code and close the window.
// TODO: Place this in it's own component.
const ConnectedToSupabase = ({ code, projectId }) => {
  const utils = trpc.useContext()

  const saveMutation = trpc.supabase.saveAuthCode.useMutation({
    onSuccess: async () => {
      await utils.supabase.listProjects.invalidate()

      setTimeout(() => {
        window.close()
      }, 1000)
    },
  })

  useEffect(() => {
    saveMutation.mutate({ code, projectId })
  }, [])

  const isLoading = saveMutation.isLoading && !saveMutation.isSuccess

  return (
    <div className="flex items-center h-full">
      <div className="p-5 m-auto space-y-6 bg-white border shadow">
        <h1 className="font-serif font-bold">Supabase</h1>
        <p className="w-96">{isLoading ? 'Connecting your account...' : 'Success! Account connected'}</p>

        <AnimatePresence>
          {!isLoading ? (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.25, duration: 0.5 }} className="overflow-hidden">
              {saveMutation.isError ? <div className="text-center">{saveMutation.isError}</div> : <div className="text-gray-600 italic">Close this tab, and return to the "connect your database" screen</div>}
            </motion.div>
          ) : (
            <div className="opacity-0">x</div>
          )}
        </AnimatePresence>
      </div>
    </div>
  )
}
