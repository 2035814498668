import * as React from 'react'

import { Card } from 'src/components/Card'

// default container while the page chunk is loading, this gets removed, when we
// ... fetch the settings and project id (which has its own loader) at the same time.
const DefaultContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <Card>
      <Card.Header title="Settings" />

      <Card.Body>
        <div className="h-6 mr-4 bg-gray-200 rounded w-60 animate-pulse"></div>
        <br />
        <div className="w-20 h-6 bg-gray-200 rounded animate-pulse"></div>
      </Card.Body>

      {children}
    </Card>
  )
}

export const SettingsLoader = ({
  removeDefaultContainer,
}: {
  removeDefaultContainer?: boolean
}) => {
  const ContainerElem = removeDefaultContainer
    ? React.Fragment
    : DefaultContainer

  return (
    <ContainerElem>
      <div className="h-96 bg-gray-200 rounded animate-pulse" />

      <Card.Body>
        <div className="text-gray-200 rounded bg-gray-200 inline-block animate-pulse w-44 h-6" />
        <br />
        <br />
        <div className="text-gray-200 rounded bg-gray-200 inline-block animate-pulse w-1/2 h-6" />
        <br />
        <br />
        <div className="h-28 w-2/3 bg-gray-200 rounded animate-pulse" />
      </Card.Body>
    </ContainerElem>
  )
}
