import { Menu as HeadlessMenu } from '@headlessui/react'

export const Menu = ({
  className = '',
  ...rest
}: React.ComponentPropsWithRef<typeof HeadlessMenu> & {
  children?: React.ReactNode
}) => (
  <HeadlessMenu
    as="div"
    className={'relative text-base font-normal z-50' + ' ' + className}
    {...rest}
  />
)

Menu.Button = (
  props: React.ComponentPropsWithRef<typeof HeadlessMenu.Button> & {
    children?: React.ReactNode
  }
) => <HeadlessMenu.Button {...props} />

Menu.Items = ({ className = '', ...rest }) => (
  <HeadlessMenu.Items
    as="div"
    className={
      'border-primary-300 absolute inline-block bg-white border rounded-sm focus:outline-none shadow-lg overflow-hidden right-0' +
      ' ' +
      className
    }
    {...rest}
  />
)

Menu.ItemSeparator = () => <div className="border-b border-gray-300" />

Menu.Item = ({
  children,
  className = '',
  ...rest
}: React.ComponentProps<typeof HeadlessMenu.Item>) => (
  <HeadlessMenu.Item
    as="div"
    className={
      'px-4 py-3 hover:bg-gray-200 cursor-pointer whitespace-nowrap' +
      ' ' +
      className
    }
    style={{
      minWidth: '120px',
    }}
    {...rest}
  >
    {children}
  </HeadlessMenu.Item>
)
