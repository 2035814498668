import { Card } from 'src/components/Card'

export const SnapshotsLoader = () => {
  return (
    <Card>
      <Card.Header title="Snapshots" />
      <Card.Body>
        <div className="h-8 my-5 bg-gray-200 rounded animate-pulse" />
        <div className="h-8 my-5 bg-gray-200 rounded animate-pulse" />
        <div className="h-8 my-5 bg-gray-200 rounded animate-pulse" />
      </Card.Body>
    </Card>
  )
}
