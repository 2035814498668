import { routes, Link, useParams, NavLink } from '@redwoodjs/router'

import { trpc } from 'src/lib/trpc'

const Crumb = ({
  to,
  label,
  isLoading,
}: {
  to: string
  label?: string
  isLoading: boolean
}) => {
  return (
    <>
      <span> &gt; </span>
      {/** @ts-expect-error: onClick is expected in NavLink!?  */}
      <NavLink className="hover:text-iris-800" to={to}>
        {isLoading ? '◻'.repeat(4) : label}
      </NavLink>
    </>
  )
}

const Container = ({ children }: { children: React.ReactNode }) => {
  return <div>{children}</div>
}

export const Breadcrumbs = () => {
  const { organizationId, databaseId: projectId, snapshotId } = useParams()
  const query = trpc.breadcrumbs.useQuery({
    organizationId,
    projectId,
    snapshotId,
  })

  if (query.status === 'error') {
    return null
  }

  return (
    <>
      <Container>
        <Link to={routes.teams()} className="hover:text-iris-800">
          Teams
        </Link>
        {organizationId && (
          <Crumb
            to={routes.team_projects({ organizationId })}
            label={query.data?.organizationName}
            isLoading={query.isLoading}
          />
        )}
        {projectId && (
          <Crumb
            to={routes.project_overview({
              organizationId,
              databaseId: projectId,
              subpage: 'overview',
            })}
            label={query.data?.projectName}
            isLoading={query.isLoading}
          />
        )}
        {snapshotId && (
          <Crumb
            to={routes.project_snapshot({
              organizationId,
              databaseId: projectId,
              snapshotId,
              section: 'summary',
            })}
            label={query.data?.snapshotName}
            isLoading={query.isLoading}
          />
        )}
      </Container>
    </>
  )
}
