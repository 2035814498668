import { Link, routes } from '@redwoodjs/router'
import React from 'react'

import { Logo } from 'src/components/Logo/Logo'

import { UserTools } from './subs/UserTools'

const NavBarItem = ({
  label,
  onClick,
}: {
  label: string
  onClick: React.MouseEventHandler<HTMLDivElement>
}) => {
  return (
    <div className="flex cursor-pointer hover:text-iris-800" onClick={onClick}>
      {label}
    </div>
  )
}

export const MainNavBar = ({
  breadcrumbs,
}: {
  breadcrumbs?: React.ReactNode
}) => {
  return (
    <div className="flex items-center w-full">
      <Link to={routes.entry()}>
        <Logo />
      </Link>

      {breadcrumbs && <div className="ml-4">{breadcrumbs}</div>}

      <div className="flex items-center ml-auto space-x-3">
        <NavBarItem
          label="Docs"
          onClick={() => {
            window.open(
              'https://docs.snaplet.dev',
              '_blank',
              'noopener noreferrer'
            )
          }}
        />

        <NavBarItem
          label="Help"
          onClick={() => {
            window.open(
              'https://app.snaplet.dev/chat',
              '_blank',
              "'noopener noreferrer'"
            )
          }}
        />

        <div className="relative">
          <UserTools />
        </div>
      </div>
    </div>
  )
}
