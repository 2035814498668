// @ts-nocheck
import { useAuth } from '@redwoodjs/auth'
import { navigate, routes } from '@redwoodjs/router'

import { Menu } from 'src/components/Menu'

import { NotificationsQuery } from './NotificationsQuery'
import { ChevronIcon } from 'src/icons/ChevronIcon'

export const UserTools = () => {
  const { currentUser, isAuthenticated } = useAuth()

  // The invite page uses the top-nav-bar and there's a possibility that the user is not authenticated
  // when they attempt to accept the invitation.
  if (!isAuthenticated) {
    return null
  }

  return (
    <Menu>
      <Menu.Button
        id="account-tab"
        className="flex items-center hover:text-iris-800"
      >
        Account <ChevronIcon className="ml-1" />
      </Menu.Button>

      <Menu.Items className="top-5 w-56">
        <div className="px-4 py-3 font-bold leading-loose">
          {currentUser?.email}
          <NotificationsQuery />
        </div>
        <Menu.ItemSeparator />
        <Menu.Item onClick={() => navigate(routes.user_access_tokens())}>
          Access Tokens
        </Menu.Item>
        <Menu.Item onClick={() => navigate(routes.user_logout())}>
          Log Out
        </Menu.Item>
        <hr className="border-gray-300" />
        <Menu.Item
          className="text-red-800"
          onClick={() => navigate(routes.account_deletion())}
        >
          Delete My Account
        </Menu.Item>
      </Menu.Items>
    </Menu>
  )
}
