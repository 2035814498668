import { MainNavBar } from 'src/components/MainNavBar/MainNavBar'
import { Breadcrumbs } from './Breadcrumbs'

export const HeaderWithBreadcrumbs = ({
  children,
}: {
  children: React.ReactNode
}) => {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="px-6 py-5 bg-white flex mb-7">
        <MainNavBar breadcrumbs={<Breadcrumbs />} />
      </div>
      {children}
    </div>
  )
}
