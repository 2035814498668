import { MainNavBar } from 'src/components/MainNavBar/MainNavBar'

export const Header = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <div className="py-2 bg-iris-800 text-white text-center">
        Snaplet is shutting down on the 31st of August.{' '}
        <a
          href="https://www.snaplet.dev/post/snaplet-is-shutting-down"
          className="font-bold underline hover:opacity-80"
          target="_blank"
          rel="noreferrer noopener"
        >
          Learn more
        </a>
      </div>
      <div className="px-6 py-5 bg-white">
        <MainNavBar />
      </div>
      {children}
    </>
  )
}
