import { ComponentProps, ReactNode } from 'react'

export const DataBrowserCard = ({
  header,
  children,
  ...rest
}: { header: ReactNode; children: ReactNode } & ComponentProps<'div'>) => {
  return (
    <div {...rest}>
      <div className="relativ flex-col flex flex-1">
        <div className="bg-white border-b border-r border-gray-200 border-opacity-40 flex flex-1 flex-col h-full">
          <div className="px-8 py-4 flex border-b border-gray-200 border-opacity-40 rounded-tl-xl justify-between items-center">
            <div className="flex-grow">{header}</div>
          </div>
          <div className="px-4 pb-4 overflow-auto flex-grow flex flex-col">
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}
