import React from 'react'

import { toast } from 'src/lib/toast'
import { trpc } from 'src/lib/trpc'

const Success = ({ status }: { status: 'NONE' | 'EMAIL' }) => {
  const [isChecked, setIsChecked] = React.useState(() => status === 'EMAIL')

  const utils = trpc.useContext()

  const { mutate, isLoading: isUpdating } =
    trpc.user.updateNotificationStatus.useMutation({
      onError: () => {
        toast.error('Error updating notification setting.')
      },
      onSuccess: () => {
        toast.success('Notification setting updated')
      },
      onSettled: () => {
        void utils.user.getNotificationStatus.invalidate()
      },
    })

  return (
    <>
      <br />
      <label className="flex items-center gap-3">
        <span
          className={[
            'flex-1 truncate text-gray-400 font-normal text-sm',
            isUpdating && 'animate-pulse',
          ].join(' ')}
        >
          Subscribe{isChecked && 'd'} to notifications
        </span>
        <input
          type="checkbox"
          className="rounded-sm mr-1"
          checked={isChecked}
          disabled={isUpdating}
          onChange={(e) => {
            const newIsChecked = e.target.checked

            setIsChecked(newIsChecked)
            mutate({ status: newIsChecked ? 'EMAIL' : 'NONE' })
          }}
        />
      </label>
    </>
  )
}

export const NotificationsQuery = () => {
  const { data, status } = trpc.user.getNotificationStatus.useQuery()

  if (status === 'loading') {
    return <div className="h-4 animate-pulse bg-gray-200 rounded" />
  }

  if (status === 'error') {
    return (
      <div className="text-red-800 font-normal text-sm">
        Failed to fetch status
      </div>
    )
  }

  return <Success status={data} />
}
