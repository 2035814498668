import { Button } from 'src/components/Button'
import { DataBrowserCard } from 'src/routes/projects/pages/DataOperations/DataBrowserCard'

export const DataOperationsLoader = () => {
  return (
    <div className="z-10 mx-auto flex flex-col flex-1 w-full">
      <DataBrowserCard
        header={
          <div className="flex justify-end">
            <Button disabled>{'Save'}</Button>
          </div>
        }
        className="flex-1"
      >
        <div className="h-screen bg-gray-200 animate-pulse" />
      </DataBrowserCard>
    </div>
  )
}
