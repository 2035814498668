import * as Sentry from '@sentry/browser'
import * as React from 'react'

import { Button } from './Button'

import { RemoveLoaderWrap } from 'src/Routes'
import { Link } from '@redwoodjs/router'
import { Card } from './Card'

export class GenericErrorBoundary extends React.Component<
  unknown,
  { hasError: boolean; error?: Error & { meta: { response: Response } } }
> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { error, hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: any) {
    Sentry.captureException(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      const response = this.state.error?.meta.response
      const code = response?.status ?? 500

      if (code >= 400 && code < 500) {
        return (
          <RemoveLoaderWrap>
            <div className="flex items-center h-full">
              <Card className="m-auto max-w-2xl" variant="popup">
                <Card.Body className="space-y-6">
                  <h1 className="font-serif font-bold">
                    {code} - {response?.statusText}
                  </h1>
                  <div className="overflow-y-auto scrollbar">
                    <pre className="font-serif text-red-800">
                      {this.state.error?.message}
                    </pre>
                  </div>
                  <p>
                    Try{' '}
                    <Link to="/logout" className="font-bold text-gray-800">
                      logging out
                    </Link>{' '}
                    and back in or{' '}
                    <Link
                      to="https://app.snaplet.dev/chat"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="font-bold text-gray-800"
                    >
                      chat with us
                    </Link>{' '}
                    in discord if you continue to experience this issue.
                  </p>

                  <Button
                    onClick={() => {
                      window.location.pathname = '/logout'
                    }}
                  >
                    Go to home
                  </Button>
                </Card.Body>
              </Card>
            </div>
          </RemoveLoaderWrap>
        )
      }

      return (
        <RemoveLoaderWrap>
          <div className="flex items-center h-full">
            <Card className="m-auto max-w-2xl" variant="popup">
              <Card.Body className="space-y-6">
                <h1 className="font-serif font-bold">
                  500 - Uncaught exception
                </h1>
                <p>
                  Something went wrong! We've logged an exception which notified
                  us on Discord.
                </p>
                <Button
                  onClick={() => {
                    window.open(
                      'https://app.snaplet.dev/chat',
                      '_blank',
                      'noopener noreferrer'
                    )
                  }}
                  variant="secondary"
                >
                  Chat with us (Discord)
                </Button>

                <div className="overflow-y-auto scrollbar">
                  <pre className="font-serif text-red-800">
                    {this.state.error?.message}
                  </pre>
                  <pre className="font-serif text-gray-400">
                    {this.state.error?.stack?.toString()}
                  </pre>
                </div>

                <Button
                  onClick={() => {
                    window.location.pathname = '/'
                  }}
                >
                  Back to home
                </Button>
              </Card.Body>
            </Card>
          </div>
        </RemoveLoaderWrap>
      )
    }

    return this.props.children
  }
}
