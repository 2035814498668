import { routes, useParams } from '@redwoodjs/router'

import { TabItem } from 'src/components/LayoutTabItem'

export const OrganizationTabs: React.FC = ({ children }) => {
  const { organizationId } = useParams()

  return (
    <>
      <div className="flex px-4 space-x-6 justify-center mb-5">
        <TabItem
          to={routes.team_projects({ organizationId })}
          label="Projects"
        />
        <TabItem to={routes.team_members({ organizationId })} label="Members" />
        <TabItem
          to={routes.team_activity({ organizationId })}
          label="Activity"
        />
        <TabItem to={routes.team_billing({ organizationId })} label="Billing" />
        <TabItem
          to={routes.team_settings({ organizationId })}
          label="Settings"
        />
        <TabItem to={routes.team_metrics({ organizationId })} label="Metrics" />
      </div>
      {children}
    </>
  )
}
